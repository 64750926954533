import { UIEventHandler, useCallback, useRef, useState } from 'react';

const SCROLL_DIFF = 200;

type InfiniteScrollParams = {
  callback: (offset: number) => Promise<unknown>;
  scrollDiff?: number;
};

const useInfiniteScroll = ({ callback, scrollDiff }: InfiniteScrollParams) => {
  // states
  const [isFetching, setFetching] = useState(false);
  // refs
  const isLoading = useRef(false);
  const fetchMore = useRef(true);
  const currentPage = useRef(0);

  const onScroll: UIEventHandler<HTMLElement> = useCallback(
    e => {
      if (!e.currentTarget) return;
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

      const currentHeight = Math.ceil(scrollTop + clientHeight);

      if (
        fetchMore.current &&
        !isLoading.current &&
        currentHeight >= scrollHeight - (scrollDiff ?? SCROLL_DIFF)
      ) {
        isLoading.current = true;
        setFetching(true);

        currentPage.current += 1;

        callback(currentPage.current).then(() => {
          setFetching(false);
          setTimeout(() => {
            isLoading.current = false;
          });
        });
      }
    },
    [callback, scrollDiff]
  );

  const setCurrentPage = useCallback(
    (value: number) => {
      if (value < 0 || isLoading.current) return;

      // Reset current page value
      currentPage.current = value;

      // Trigger the callback immediately with the new page
      isLoading.current = true;
      setFetching(true);

      callback(currentPage.current).then(() => {
        setFetching(false);
        setTimeout(() => {
          isLoading.current = false;
        });
      });
    },
    [callback]
  );

  const stopPaging = useCallback(() => {
    fetchMore.current = false;
  }, []);

  const startPaging = useCallback(() => {
    fetchMore.current = true;
  }, []);

  return {
    onScroll,
    stopPaging,
    startPaging,
    setPage: setCurrentPage,
    currentPage,
    isFetching
  };
};

export default useInfiniteScroll;
