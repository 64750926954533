import { gql } from '@apollo/client';

export const MATCHES_LIST_FRAGMENT = gql`
  fragment MatchesListFragment on Match {
    id
    removed
    name
    team1
    team2
    leagueId
    creationId
    startTime
    region
    isOutOfPackage
    bookingStatus
    sport
    streamer
    status
    reason
    provider
    bookingType
    league {
      name
      id
      providerLeagues {
        id
        provider {
          id
          name
        }
      }
    }
    stream {
      ... on FStream {
        id
        title
        streamId
        streamerId
        matchId
        matchCreationId
        streamName
        serverName
        streamUrl
        inputId
        removed
        active
        additionalInfo {
          id
          title
          tracks {
            video {
              bitrate
              codec
              resolution
            }
            audio {
              bitrate
              codec
              resolution
            }
          }
          duration
          alive
          clientCount
          bufferings
          urls {
            embed {
              url
              streamName
            }
            hls {
              url
              streamName
            }
            hlsApple {
              url
              streamName
            }
            hlsHttp {
              url
              streamName
            }
            rtmp {
              url
              streamName
            }
          }
        }
      }
      ... on StreamError {
        code
        response {
          url
          status
          statusText
          body
        }
      }
    }
  }
`;

export const MATCHES_BOOKED_FRAGMENT = gql`
  fragment MatchesBookedFragment on BookedMatch {
    id
    leagueName
    team1
    team2
    leagueId
    creationId
    sportId
    region
    sport
    startTime
    endTime
    status
    bookingType
    streamId
  }
`;

export const COUNTRIES_PRICES_FRAGMENT = gql`
  fragment CountriesPricesFragment on MatchesCountryPrice {
    id
    price
    country
    countryId
  }
`;
