export type BookingStatusProps = {
  status: string | null;
  isHidden: boolean | null;
};

export enum BookStatus {
  not_booked = 'Not Booked',
  partially_booked = 'Partially Booked',
  fully_booked = 'Fully Booked'
}
