import { gql } from '@apollo/client';

import { MATCHES_BOOKED_FRAGMENT, MATCHES_LIST_FRAGMENT } from './fragments';

export const GET_BUYER_UPCOMING_EVENTS = gql`
  query BuyerListMatches(
    $query: GetMatchesInput
    $partnerId: String!
    $organizationId: String!
  ) {
    buyerListMatches(
      query: $query
      partnerId: $partnerId
      organizationId: $organizationId
    ) {
      data {
        results {
          ...MatchesListFragment
        }
        total
        todaysTotal
      }
    }
  }
  ${MATCHES_LIST_FRAGMENT}
`;

export const GET_BOOKED_EVENTS = gql`
  query BookedMatches($query: BookedMatchesInput, $partnerId: String!) {
    bookedMatches(query: $query, partnerId: $partnerId) {
      data {
        results {
          ...MatchesBookedFragment
        }
        total
      }
    }
  }
  ${MATCHES_BOOKED_FRAGMENT}
`;

export const GET_HISTORY_EVENTS = gql`
  query HistoryMatchesBuyer($query: GetMatchesInput, $organizationId: String) {
    historyMatchesBuyer(query: $query, organizationId: $organizationId) {
      data {
        results {
          ...MatchesListFragment
        }
        total
      }
    }
  }
  ${MATCHES_LIST_FRAGMENT}
`;

export const MATCH_COUNTRY_PRICE_SUM = gql`
  query MatchCountryPriceSum($id: ID!) {
    matchCountryPriceSum(id: $id) {
      data {
        sum
      }
    }
  }
`;

export const GET_AUTOBOOKING_COUNTRIES = gql`
  query GetMatchAutoBookingCountries($query: AutoBookingCountriesQuery) {
    getMatchAutoBookingCountries(query: $query) {
      data {
        country
        price
        id
      }
    }
  }
`;

export const GET_BOOKING_COUNTRIES = gql`
  query GetMatchBookingCountries($query: BookingCountriesInput) {
    getMatchBookingCountries(query: $query) {
      data {
        bookingType
        country
        price
        id
      }
    }
  }
`;

export const GET_AVAILABLE_MATCHES = gql`
  query GetAvailableMatches($query: GetAvailableMatchesInput!) {
    getAvailableMatches(query: $query) {
      data
    }
  }
`;

export const GET_STREAM_URL = gql`
  query GetStreamUrl($query: GetStreamUrlForMatchesInput!) {
    getStreamUrlForMatches(query: $query) {
      data {
        url
        title
      }
    }
  }
`;
