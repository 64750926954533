import styled from '@shared_medialab/ui_components';
import { css } from 'styled-components';
import { MATCH_STATUS_IDS, MatchTypesEnum } from 'constants/match';

import {
  IMatchItemColProps,
  IStyledMatchItemProps,
  IStyledMatchTimeContainerProps,
  UserProviderType
} from './types';

export const MatchItemCol = styled.div<IMatchItemColProps>`
  display: flex;
  align-items: center;
  height: 100%;
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .copy-icon {
    opacity: 0;
    transition: opacity 0.4s;
    & > div > div > div {
      background-color: transparent;
      span {
        color: var(--primaryNew);
      }
    }
  }
  svg {
    path {
      fill: var(--gray);
    }
  }
  &:hover {
    .copy-icon {
      opacity: 1;
    }
  }
  ${({
    width,
    theme,
    align = 'flex-start',
    overflow = 'hidden',
    isLast,
    isFirst,
    isPlayButton,
    isButton
  }) => css`
    width: ${width}%;
    flex: 1 0 ${width}%;
    justify-content: ${align};
    padding: 0 ${theme.spaces.sm};
    ${isLast &&
    css`
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    `}
    ${isFirst &&
    css`
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    `}
    ${isPlayButton &&
    css`
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    `}
    ${isButton &&
    css`
      border: 1px solid var(--light-blue);
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      &:hover {
        border: 1px solid var(--royal-blue);
      }
    `}
    margin-right: ${isLast ? 0 : '3px'};
    p {
      overflow: ${overflow};
      ${align === 'flex-end' && 'direction: rtl;'}
    }
  `}
`;

export const MatchTimeContainer = styled.div<IStyledMatchTimeContainerProps>`
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 22px;
  span {
    font-weight: 600;
  }
  ${({ theme, status, type }) => css`
    display: ${status !== MATCH_STATUS_IDS.live &&
    type === MatchTypesEnum.history
      ? 'none'
      : 'flex'};
    padding: ${theme.spaces.xs} ${theme.spaces.base};
    color: ${theme.colors.light[0]};
    font-size: ${theme.sizes.sm};
  `}
`;

export const UserProvider = styled.p<UserProviderType>`
  width: 6vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledMatchItem = styled.div<IStyledMatchItemProps>`
  ${({ theme, active }) => css`
    margin: 3px 0;
    height: 34px;
    transition: all 0.3s ease;
    border-radius: 6px;
    ${MatchItemCol} {
      background-color: ${active ? 'var(--lavender)' : theme.colors.light[0]};
    }
    ${MatchItemCol}>p,
    ${MatchItemCol}>div {
      transition: color 0.3s;
      color: ${theme.colors.text};
      font-size: ${theme.sizes.sm};
    }
    &:hover {
      ${MatchItemCol} {
        background-color: var(-primary-bg-2);
        & > p {
          color: var(--royal-blue);
        }
        & > div {
          color: var(--royal-blue);
        }
        svg {
          path {
            fill: var(--royal-blue) !important;
          }
        }
      }
      border-bottom: 1px solid var(--royal-blue);
    }
  `}
`;

export const StyledContainer = styled.div`
  .btn {
    background-color: transparent;
    :hover {
      background-color: transparent;
    }
  }
  .unbook-tooltip {
    border: 1px solid var(--lavender);
    border-radius: 6px;
    background-color: var(--white);
    &:hover {
      background-color: var(--lavender);
    }
  }
`;
