import { FC } from 'react';
import { Tooltip } from '@shared_medialab/ui_components';
import { MatchTypesEnum } from 'constants/match';
import { useTranslation } from 'react-i18next';
import { MatchesListFragment } from 'gql/events/types/MatchesListFragment';

import { WrapperProps, TooltipProps } from './types';

const TooltipElement: FC<TooltipProps> = ({ children, title }) => {
  return (
    <Tooltip
      styles={{
        position: 'top',
        bgColor: 'var(--gray)',
        textColor: 'var(--white)'
      }}
      title={title}
    >
      {children}
    </Tooltip>
  );
};

const Wrapper: FC<WrapperProps> = ({
  children,
  item,
  type,
  hasBookPermission
}) => {
  const { t } = useTranslation('events');

  if (type === MatchTypesEnum.list) {
    if ((item as MatchesListFragment)?.isOutOfPackage) {
      return (
        <TooltipElement title={t('cart:out_of_package')}>
          {children}
        </TooltipElement>
      );
    } else if (!hasBookPermission) {
      return (
        <TooltipElement title={t('cart:no_book_permission')}>
          {children}
        </TooltipElement>
      );
    }
  }

  return children;
};

export default Wrapper;
