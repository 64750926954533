import { Tooltip } from '@shared_medialab/ui_components';
import { FC, useMemo } from 'react';

import { BookingStatusProps, BookStatus } from './types';
import './index.css';

const BookingStatus: FC<BookingStatusProps> = ({ status, isHidden }) => {
  const statusClass = useMemo(() => {
    switch (status) {
      case BookStatus.not_booked:
        return 'status-unbooked';
      case BookStatus.partially_booked:
        return 'status-partial-booked';
      case BookStatus.fully_booked:
        return 'status-fully-booked';
      default:
        return 'status-unbooked';
    }
  }, [status]);

  return (
    <Tooltip
      styles={{ position: 'left' }}
      title={status || 'Status'}
      className={isHidden ? 'hidden' : ''}
    >
      <div className={`booking-status ${statusClass}`} />
    </Tooltip>
  );
};

export default BookingStatus;
