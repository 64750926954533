import {
  FC,
  MutableRefObject,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
// import { money } from 'helpers/money';
import { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import {
  Icon,
  PlayButton,
  Popover,
  Loading
} from '@shared_medialab/ui_components';
// import { useUIDispatchContext } from 'providers/UI';
// import { ModalKeys } from 'providers/UI/types';
import { useLazyQuery, useQuery } from '@apollo/client';
import { UserMe } from 'gql/auth/types/UserMe';
import { GET_USER_DATA } from 'gql/auth/queries';
// import { BOOK_MATCHES_QUICK } from 'gql/events/mutations';
// import { toast } from 'react-toastify';
import {
  GET_BOOKED_EVENTS,
  GET_BUYER_UPCOMING_EVENTS,
  GET_STREAM_URL,
  // GET_BUYER_UPCOMING_EVENTS,
  MATCH_COUNTRY_PRICE_SUM
} from 'gql/events/queries';
import { MatchCountryPriceSum } from 'gql/events/types/MatchCountryPriceSum';
import client from 'apolloClient';
import { useProjectStateContext } from 'providers/Projects';
// import * as permissionConstants from 'constants/permissions';
// import { MatchTypesEnum } from 'constants/match';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { EVENTS_PAGE_SIZE } from 'constants/events';
import { BookedMatches } from 'gql/events/types/BookedMatches';
import { shallowEqual } from 'react-redux';
import {
  selectEventsItemStreamUrlError,
  selectEventsPagination,
  selectFormattedEventsFilters
} from 'store/slice/filters/events/selector';
import { MatchTypesEnum } from 'constants/match';
import { BuyerListMatches } from 'gql/events/types/BuyerListMatches';
import { GetStreamUrl } from 'gql/events/types/GetStreamUrl';

import {
  VideoPreviewContainer,
  VideoPreviewHeader,
  VideoPreviewWrapper
} from './styled';
import VideoJs from '../VideoJsPlayer';
import { IVideoPreviewProps } from './types';
// import { ConfirmModalTypes } from '../ConfirmModal/types';
// import Private from '../Private';
import { UserEnum } from 'pages/UserManagement/constants';
import { RouteParams as EventsRouterParams } from 'pages/Events/components/Content/types';
import ErrorPreview from './ErrorPreview';

const VideoPreview: FC<IVideoPreviewProps> = ({
  isSelected,
  matchId,
  disabled,
  matchCreationId
}) => {
  // navigation
  const { type } = useParams() as EventsRouterParams;
  // states
  const [isOpen, setOpen] = useState(false);
  const [isThereError, setIsThereError] = useState(false);
  const [currentErrorMessage, setCurrentErrorMessage] = useState('');
  // context
  // const { toggleModal } = useUIDispatchContext();

  // redux
  const { page } = useAppSelector(selectEventsPagination(type));
  const filters = useAppSelector(
    selectFormattedEventsFilters(type),
    shallowEqual
  );

  const error = useAppSelector(selectEventsItemStreamUrlError(type));

  const { selectedProject } = useProjectStateContext();

  const listData =
    client.readQuery<BuyerListMatches>({
      query: GET_BUYER_UPCOMING_EVENTS,
      variables: {
        query: {
          pagination: {
            page,
            limit: EVENTS_PAGE_SIZE
          },
          ...filters
        },
        skip: type !== MatchTypesEnum.list,
        organizationId: selectedProject?.id,
        partnerId: selectedProject?.partnerId
      }
    })?.buyerListMatches?.data.results || [];

  const bookedData =
    client.readQuery<BookedMatches>({
      query: GET_BOOKED_EVENTS,
      variables: {
        query: {
          organizationId: selectedProject?.id,
          pagination: {
            page,
            limit: EVENTS_PAGE_SIZE
          },
          ...filters
        },
        skip: type !== MatchTypesEnum.booked,
        partnerId: selectedProject?.partnerId
      }
    })?.bookedMatches?.data.results || [];

  //Todo:comments for quick buy
  const matchData = type === MatchTypesEnum.list ? listData : bookedData;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const matchItem = matchData.filter((elem: any) => elem.id === matchId)[0];

  const [
    getUserData
    //  { data }
  ] = useLazyQuery<UserMe>(GET_USER_DATA);

  useEffect(() => {
    const organizationId =
      localStorage.getItem('projectId') || selectedProject?.id;

    getUserData({
      fetchPolicy: 'cache-only',
      variables: {
        ...(organizationId ? { organizationId } : {}),
        groupName: UserEnum.buyer
      }
    });
  }, [getUserData, selectedProject?.id]);

  const {
    //  data: price,
    loading
  } = useQuery<MatchCountryPriceSum>(MATCH_COUNTRY_PRICE_SUM, {
    fetchPolicy: 'no-cache',
    skip: !isOpen,
    variables: {
      id: matchId
    }
  });

  const getStreamUrlQueryBooked = {
    matchCreationId: Number(matchItem?.creationId),
    organizationId: Number(selectedProject?.id),
    ...(matchItem?.__typename === 'Match'
      ? matchItem?.stream?.__typename === 'FStream' &&
        matchItem?.stream?.streamId
        ? { streamId: +matchItem.stream.streamId }
        : {}
      : matchItem?.streamId
      ? { streamId: +matchItem.streamId }
      : {})
  };

  const getStreamUrlQueryList = {
    ...getStreamUrlQueryBooked,
    isDemo: true
  };

  const { data: stream } = useQuery<GetStreamUrl>(GET_STREAM_URL, {
    fetchPolicy: 'no-cache',
    skip: !isOpen,
    variables: {
      query:
        type == MatchTypesEnum.list
          ? getStreamUrlQueryList
          : getStreamUrlQueryBooked
    }
  });

  const { url: streamUrl } = stream?.getStreamUrlForMatches?.data || {};

  // const defaultCountries = data?.me?.data.settings.countries || [];

  // const [BookMatchesQuick] = useMutation(BOOK_MATCHES_QUICK, {
  //   onCompleted() {
  //     toast.success('Successfully booked');
  //     client.refetchQueries({ include: [GET_BUYER_UPCOMING_EVENTS] });
  //   },
  //   onError(error) {
  //     toast.error(error.message);
  //   }
  // });

  // const priceSum = price?.matchCountryPriceSum?.data.sum;

  // const onClickQuickBuy = () => {
  //   if (!defaultCountries.length) {
  //     return toggleModal(ModalKeys.confirm, true, {
  //       title: 'info',
  //       message: t('set_default_countries'),
  //       confirmText: t('open_settings'),
  //       cancelText: t('common:close'),
  //       type: ConfirmModalTypes.primaryNew,
  //       onConfirm: () => {
  //         toggleModal(ModalKeys.settings, true);
  //       }
  //     });
  //   }

  //   return BookMatchesQuick({
  //     variables: {
  //       input: {
  //         matchId: matchId,
  //         organizationId: selectedProject?.id
  //       }
  //     }
  //   });
  // };

  // translations
  // const { t } = useTranslation('video_preview');

  const handlePlayerReady = useCallback(
    (player: VideoJsPlayer, ref: MutableRefObject<VideoJsPlayer | null>) => {
      ref.current = player;
      player.on('error', function () {
        if (player.error()?.code === 4) {
          setIsThereError(true);
        }
      });
    },
    []
  );

  const videoJsOptions: VideoJsPlayerOptions = {
    autoplay: false,
    controls: true,
    muted: true,
    controlBar: {
      fullscreenToggle: false,
      pictureInPictureToggle: false
    },
    sources: streamUrl
      ? [
          {
            src: streamUrl,
            type: 'application/x-mpegURL' // type for HLS
          }
        ]
      : []
  };

  const playerRef = useRef<VideoJsPlayer | null>(null);

  const matchVideoTitle = useMemo(() => {
    if (matchItem?.team1 && matchItem?.team2) {
      return `${matchItem?.team1} - ${matchItem?.team2}`;
    } else {
      return '';
    }
  }, [matchItem?.team1, matchItem?.team2]);

  useEffect(() => {
    if (
      (error.matchId === matchCreationId && error.value && error.message,
      error.partnerId === selectedProject?.partnerId)
    ) {
      setIsThereError(true);
      setCurrentErrorMessage(error.message);
    }
  }, [
    error.matchId,
    error.message,
    error.partnerId,
    error.value,
    matchCreationId,
    selectedProject?.partnerId
  ]);

  return (
    <Popover
      selfSizing
      opened={isOpen}
      onClose={() => {
        setOpen(false);
        setIsThereError(false);
      }}
      onOpen={() => setOpen(true)}
      content={
        <VideoPreviewWrapper onClick={e => e.stopPropagation()}>
          {loading && <Loading overlay blurred={0.1} />}
          <VideoPreviewHeader>
            <p>{matchVideoTitle}</p>
            <button
              onClick={e => {
                e.stopPropagation();
                setOpen(false);
              }}
            >
              <Icon type="clear" size={20} />
            </button>
          </VideoPreviewHeader>
          <VideoPreviewContainer>
            {!isThereError ? (
              <VideoJs
                options={videoJsOptions}
                onReady={player => handlePlayerReady(player, playerRef)}
              />
            ) : (
              <ErrorPreview massage={currentErrorMessage} />
            )}
          </VideoPreviewContainer>
          {/* {type === MatchTypesEnum.list && (
            <Private
              routeKey={permissionConstants.QUICK_BUY}
              routeId={permissionConstants.BUYER_ROUTES_IDS.list}
            >
              <Button
                color="linear"
                icon="shopping-bag"
                disabled
                onClick={() => onClickQuickBuy()}
              >
                {t('quick_buy')}{' '}
                {!!(defaultCountries.length && priceSum) && money(priceSum)}
              </Button>
            </Private>
          )} */}
        </VideoPreviewWrapper>
      }
    >
      <PlayButton highlighted={!isSelected} disabled={disabled} />
    </Popover>
  );
};

export default memo(VideoPreview);
