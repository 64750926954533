import { FC, useMemo } from 'react';
import { Typography } from '@shared_medialab/ui_components';
import { useLocation, useParams } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MatchTypesEnum } from 'constants/match';
import { useAppSelector } from 'store/hooks';
import { selectFormattedEventsFilters } from 'store/slice/filters/events/selector';
import { useProjectStateContext } from 'providers/Projects';
import * as permissionConstants from 'constants/permissions';

import ExportButton from 'components/shared/ExportButton';
import { RouteParams } from '../Content/types';
import { ROUTES } from '../../constants';
import useEventsUpcomingCache from 'hooks/useEventUpcomingCache';
import useEventsBookedCache from 'hooks/useEventsBookedCache';
import useEventsHistoryCache from 'hooks/useEventsHistoryCache';
import Total from '../Total';
import Private from 'components/shared/Private';
import UnBookSection from '../MatchItem/components/UnBookSection';

const { Text } = Typography;

const RightActions: FC = () => {
  const { pathname } = useLocation();
  const { type } = useParams() as RouteParams;
  const { selectedProject } = useProjectStateContext();
  const { t } = useTranslation('common');

  const baseURL = `${process.env.REACT_APP_BUYER_API_URL}/matches/history/${selectedProject?.id}`;

  const historyFilters = useAppSelector(
    selectFormattedEventsFilters(MatchTypesEnum.history),
    shallowEqual
  );

  const { total: upcomingTotal } = useEventsUpcomingCache();
  const { total: bookedTotal } = useEventsBookedCache();
  const { total: historyTotal } = useEventsHistoryCache();

  const total = useMemo(() => {
    switch (type) {
      case MatchTypesEnum.list:
        return upcomingTotal;
      case MatchTypesEnum.booked:
        return bookedTotal;
      case MatchTypesEnum.history:
        return historyTotal;
      default:
        return null;
    }
  }, [bookedTotal, historyTotal, type, upcomingTotal]);

  switch (pathname) {
    case ROUTES.list:
      return <Total data={total} />;
    case ROUTES.booked:
      return (
        <>
          <Total data={total} />
          <Private
            routeId={permissionConstants.BUYER_ROUTES_IDS.booked}
            routeKey={permissionConstants.UNBOOK}
          >
            <UnBookSection />
          </Private>
        </>
      );
    case ROUTES.history:
      return (
        <div className="flex-display flex-align-items-center pr--12">
          {!!total && (
            <div className="mr--40">
              <Text bold className="mr--8" level={13}>{`${t('total')}:`}</Text>
              <Text level={13}>{total}</Text>
            </div>
          )}
          <ExportButton
            uri={baseURL}
            fileName="History.xlsx"
            params={historyFilters}
          />
        </div>
      );
    default:
      return null;
  }
};

export default RightActions;
